import React from "react";
import Bloglists from "../../components/Blog/Blogsingle";

const BlogsSingle = () => {
  return (
    <div>
      <Bloglists />
    </div>
  );
};

export default BlogsSingle;
