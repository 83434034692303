import React, { useEffect, useState } from "react";
import css from "./Work.module.scss";
import { format } from "timeago.js";
import { motion } from "framer-motion";
import FsLightbox from "fslightbox-react";
import ReactPlayer from "react-player";
import { Modal, Button, Text } from "@mantine/core";
import {
  draw,
  fadeIn,
  slideIn,
  staggerChildren,
  staggerContainer,
  textVariant2,
  zoomIn,
} from "../../utils/motion";
import { useNavigate } from "react-router";
import { MdOutlineFormatIndentIncrease } from "react-icons/md";

const Livestreams = () => {
  const [fetchedList, setFetchedList] = useState();
  const [toggler, setToggler] = useState();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [countdown, setCountdown] = useState(4);
  const [timer, setTimer] = useState(null);
  const [redirectReady, setRedirectReady] = useState(false);
  const [isAutoPlay, setIsAutoPlay] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchVideos();
  }, []);

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ selectedVideo:", selectedVideo);
    if (selectedVideo) {
      if (isAutoPlay) {
        setIsModalOpen(true);

        const newTimer = setInterval(() => {
          setCountdown((prev) => {
            if (prev === 1) {
              clearInterval(newTimer);
              setRedirectReady(true);
            } else if (prev < 0) {
              clearInterval(newTimer);
              setTimer(null);
              return 4;
            }

            return prev - 1;
          });
        }, 1000);
        return () => setTimer(newTimer);
      } else {
        setIsAutoPlay(false);
        setCountdown(0);
        setRedirectReady(true);
      }
    }
  }, [selectedVideo]);
  useEffect(() => {
    if (redirectReady && countdown == 0) {
      redirectToLiveVideo();
    }
    // console.log("🚀 ~ Livestreams ~ redirectReady:", redirectReady);
    // console.log("🚀 ~ Livestreams ~ countdown:", countdown);
  }, [countdown, redirectReady]);
  function redirectToLiveVideo() {
    setIsModalOpen(false);
    navigate(`/video/${selectedVideo}`);
  }
  function cancelRedirect() {
    clearInterval(timer); // Clear the timer to prevent redirection

    setRedirectReady(false);
    setIsModalOpen(false);
    setCountdown(-1);
    setTimer(null);
  }
  function convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    return newDate;
  }
  const startVideoPlayer = (videoId) => {
    setSelectedVideo(null); // Reset the selected video

    setTimeout(() => {
      setSelectedVideo(videoId); // Set the new selected video after a brief delay
    }, 0);
  };
  async function fetchVideos() {
    return fetch(
      `${"https://api.kampalacentraladventist.org/"}api/YouTubeChannel/GetYoutubeVideos?page=1&pageSize=5`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data) {
          console.log("error on api call", data);
          return;
        }
        setFetchedList(data);
        data.forEach((video) => {
          if (video.isLiveNow) {
            setIsAutoPlay(true);
            startVideoPlayer(video.videoId);
          }
        });
      })
      .catch((error) => console.error(error))
      .finally(() => {});
  }
  return (
    <>
      {/* Your component content */}
      <Modal
        opened={isModalOpen}
        onClose={cancelRedirect}
        title="KCC is currently live"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Text>
          We are redirecting you to the live video in {countdown} seconds.
        </Text>
        <Button onClick={cancelRedirect} color="red" mt="md">
          Cancel
        </Button>
      </Modal>

      <FsLightbox
        toggler={toggler}
        sources={[
          <iframe
            width="1920"
            height="1080"
            src={`https://www.youtube.com/embed/${selectedVideo}?autoplay=1&controls=1&modestbranding=0&showinfo=0&rel=0&disablekb=1`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
            allowFullScreen
          ></iframe>,
        ]}
      />

      <motion.section
        variants={staggerChildren}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.1 }}
        className={`paddings ${css.wrapper}`}

        // variants={staggerContainer}
        // initial="hidden"
        // whileInView="show"
        // viewport={{ once: false, amount: 0.25 }}
        // className={`innerWidth ${css.container}`}
      >
        <a className="anchor" id="work"></a>

        <div className={`innerWidth flexCenter ${css.container}`}>
          {/* heading */}
          <span className="primaryText yPaddings">Live streams</span>

          {fetchedList && (
            <div className={`flexCenter ${css.experiences}`}>
              {fetchedList.map((fetchedList, i) => {
                return (
                  <motion.div
                    variants={textVariant2}
                    key={i}
                    className={`flexCenter ${css.exp}`}
                  >
                    <div
                      className={css.post}
                      style={{ cursor: "pointer" }}
                      onClick={() => startVideoPlayer(fetchedList.videoId)}
                    >
                      <h6>{fetchedList.title}</h6>
                      <p>
                        {" "}
                        {format(
                          convertUTCDateToLocalDate(
                            new Date(fetchedList.publishTime)
                          )
                        )}
                      </p>
                    </div>
                    <div className={css.role}>
                      <img
                        onClick={() => startVideoPlayer(fetchedList.videoId)}
                        src={fetchedList.thumbnailUrl}
                        alt=""
                        style={{
                          borderRadius: "5px",
                          width: "100%",
                          marginTop: "4rem",
                          marginBottom: "4rem",
                        }}
                      />
                      {/* <p>{fetchedList.description}</p> */}
                    </div>
                  </motion.div>
                );
              })}

              {fetchedList && (
                <motion.div variants={zoomIn(1, 1)} className={css.progressbar}>
                  <motion.div
                    variants={fadeIn("down", "tween", 2, 1.5)}
                    className={css.line}
                  ></motion.div>
                  <div>
                    <div
                      className={css.circle}
                      style={{ background: "#286F6C" }}
                    ></div>
                  </div>
                  <div>
                    <div
                      className={css.circle}
                      style={{ background: "#F2704E" }}
                    ></div>
                  </div>
                  <div>
                    <div
                      className={css.circle}
                      style={{ background: "#EEC048" }}
                    ></div>
                  </div>
                  <div>
                    <div
                      className={css.circle}
                      style={{ background: "#286F6C" }}
                    ></div>
                  </div>
                  <div>
                    <div
                      className={css.circle}
                      style={{ background: "#F2704E" }}
                    ></div>
                  </div>
                </motion.div>
              )}
            </div>
          )}
        </div>
      </motion.section>
    </>
  );
};

export default Livestreams;
