import React, { useEffect, useState } from "react";
import {
  ChakraProvider,
  Container,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { redirect } from "react-router";

const PaymentMessage = ({ resultDirect = null }) => {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const base64Response = urlParams.get("response");

    if (base64Response || resultDirect) {
      // Decode the base64 response
      try {
        let result = null;
        if (!resultDirect) {
          const cleanedValue = base64Response.split("%");
          const decodedResponse = atob(cleanedValue[0]);
          result = JSON.parse(decodedResponse);
        } else {
          result = resultDirect;
        }
        if (
          result.transactionRef &&
          (result.remoteResponseCode || result.responseCode) &&
          result.paymentType != "momo"
        ) {
          ApiCallback(result.transactionRef);
        }
        // Set the response state
        setResponse(result);
      } catch {}
    }
  }, []);

  const ApiCallback = async (requestRef) => {
    const apiUrl = process.env.REACT_APP_PUBLIC_API_URL;
    console.log(apiUrl);
    const response = await axios.get(
      `${process.env.REACT_APP_PUBLIC_API_URL}/api/Cart/AfterCardIPGPayment?RequestRef=${requestRef}`
    );
    console.log("🚀 ~ makePayment ~ response:", response);
    if (response.status === 200) {
      console.log("🚀 ~ makePayment ~ response:", response);
    }
  };

  return (
    <ChakraProvider>
      <Container centerContent mt={10} mb={10}>
        {response &&
          (response.remoteResponseCode === "0" ||
          response.responseCode === "00" ? (
            <Alert
              status="success"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Thank you for paying your Offertory!
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                Payment Reference: {response.transactionRef}
              </AlertDescription>
            </Alert>
          ) : (
            <Alert
              status="error"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Payment Failed
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                There was an error in registering your payment. If your account
                was debited, please contact admin.
              </AlertDescription>
            </Alert>
          ))}
      </Container>
    </ChakraProvider>
  );
};

export default PaymentMessage;
