import React, { useEffect, useState } from "react";
import { TextInput } from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import "./DonationForm.css";
import css from "./../Footer/Footer.module.scss";
import { IconX } from "@tabler/icons";
import CustomSelect from "./CustomeSelect";
import mobileMoney from "../../assets/images/mtnairtel.png";
import axios from "axios";
import Paypal from "../../assets/images/paypal.png";
import {
  Box,
  Image,
  Text,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Select,
  Checkbox,
} from "@chakra-ui/react";
import {
  BiArrowFromRight,
  BiChevronLeft,
  BiChevronRight,
} from "react-icons/bi";
import PayPalForm from "./PayPalForm";
import PaymentModal from "./PaymentModal";
import { PhoneInput, usePhoneValidation } from "react-international-phone";
import "react-international-phone/style.css";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import ChurchFamilySelect from "./ChurchFamilySelect";

const PayWithVisaCard = ({
  handleCancel,
  handleInitiateTransaction,
  total,
  currency,
  donation,
  completeOnPage,
}) => {
  const [nameValidation, setNameValidation] = useState(true);
  const [emailValidation, setEmailValidation] = useState(true);
  const [onBlur, setOnBlur] = useState(false);
  console.log("🚀 ~ file: DonationForm.jsx:281 ~ onBlur:", onBlur);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [initiateValidationObj, setInitiateValidationObj] = useState({
    lastName: "",
    firstName: "",
    phone: "",
    email: "",
    processingCovered: false,
  });
  const [phone, setPhone] = useState("");
  const [phoneOnBlur, setPhoneOnBlur] = useState(false);
  // console.log("🚀 ~ file: DonationForm.jsx:293 ~ phoneOnBlur:", phoneOnBlur);
  const phoneValidation = usePhoneValidation(phone);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [paymentId, setPaymentId] = useState();
  function openPopupOrTab(url) {
    const isPC = !/Mobi|Android/i.test(navigator.userAgent);

    if (isPC) {
      // Open as a popup window if on PC
      const width = 800;
      const height = 600;
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;

      const popupWindow = window.open(
        url,
        "_blank",
        `width=${width},height=${height},top=${top},left=${left}`
      );

      if (popupWindow) {
        popupWindow.focus();
      }
    } else {
      // Open as a new tab on mobile devices
      const newTab = window.open(url, "_blank");
      if (newTab) {
        newTab.focus();
      }
    }
  }
  const makePayment = async (paymentData) => {
    if (paymentData.manualTrigger) {
      console.log(
        "🚀 ~ file: PaymentHandler.jsx:10 ~ makePayment ~ paymentData.manualTrigger:",
        paymentData.manualTrigger
      );
      try {
        setPaymentStatus("initiating");
        const apiUrl = process.env.REACT_APP_PUBLIC_API_URL;
        console.log(apiUrl);
        const response = await axios.post(
          `${process.env.REACT_APP_PUBLIC_API_URL}/api/Cart/BeforeCardIpgPayment`,
          paymentData
        );
        console.log("🚀 ~ makePayment ~ response:", response);
        if (response.status === 200 && response.data.redirectUrl) {
          console.log("🚀 ~ makePayment ~ response:", response);
          console.log(
            "🚀 ~ makePayment ~ response.redirectUrl:",
            response.data.redirectUrl
          );
          // Payment successful, start polling for payment status
          if (completeOnPage) {
            openPopupOrTab(response.data.redirectUrl);
          } else {
            window.location.href = response.data.redirectUrl;
          }
        } else {
          // Handle payment failure or other cases here
          console.log("🚀 ~ makePayment ~ response:", response);
          setPaymentStatus("failed");
        }
      } catch (error) {
        console.error("Error making payment:", error);
        setPaymentStatus("failed");
      }
    }
  };

  const pollForPaymentStatus = async (paymentId, maxPollingAttempts = 36) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API_URL}/api/Cart/GetPaymentStatus?paymentId=${paymentId}`
      );
      if (response.status === 200 && parseFloat(response.data.status) === 8) {
        setPaymentStatus("failed"); // Payment status received
      } else if (
        response.status === 200 &&
        parseFloat(response.data.status) > 9
      ) {
        setPaymentStatus("approved"); // Payment status received
      } else if (maxPollingAttempts > 0) {
        // Continue polling until max polling attempts reached
        setTimeout(
          () => pollForPaymentStatus(paymentId, maxPollingAttempts - 1),
          5000
        ); // Poll every 5 seconds
      } else {
        // Polling timed out
        setPaymentStatus("timeout");
      }
    } catch (error) {
      console.error("Error polling for payment status:", error);
      setPaymentStatus("failed"); // Polling failed
    }
  };
  const makeAPICall = () => {
    initiateValidationObj.phone = phone;
    initiateValidationObj.offerDetail = donation;
    initiateValidationObj.manualTrigger = true;

    console.log(
      "🚀 ~ file: DonationForm.jsx:297 ~ makeAPICall ~ initiateValidationObj:",
      initiateValidationObj
    );
    console.log(
      "🚀 ~ file: DonationForm.jsx:305 ~ makeAPICall ~ donation:",
      donation
    );

    const paymentStatus = makePayment(initiateValidationObj);
    setIsLoading(true);
    setStatus("initiating");
    setStatus(paymentStatus);
  };
  const onClosePaymentModal = () => {
    setIsLoading(false);
  };
  const onTryAgain = () => {
    if (validateUserDetails()) {
      handleInitiateTransaction(initiateValidationObj);
      makeAPICall();
    }
  };
  const onKeepWaiting = (paymentId) => {
    setPaymentStatus("waiting");
    pollForPaymentStatus(paymentId, 10);
  };
  console.log(
    "🚀 ~ file: DonationForm.jsx:265 ~ PayWithMomoForm ~ initiateValidationObj:",
    initiateValidationObj
  );
  const validatePhoneNumber = (phoneNumber) => {
    console.log(
      "🚀 ~ file: DonationForm.jsx:270 ~ validatePhoneNumber ~ phoneNumber:",
      phoneNumber
    );

    const ugandaPhoneNumberPattern =
      /^(\+256|0)(7[0123456789]|3[1]|2[0])\d{7}$/;
    return ugandaPhoneNumberPattern.test(phoneNumber);
  };
  useEffect(() => {
    if (onBlur) {
      validateUserDetails();
    }
  }, [initiateValidationObj]);
  const validateUserDetails = () => {
    // console.log(
    //   "🚀 ~ file: DonationForm.jsx:330 ~ validateUserDetails ~ phoneValidation.isValid:",
    //   phoneValidation.isValid
    // );
    // console.log(
    //   "🚀 ~ file: DonationForm.jsx:332 ~ validateUserDetails ~ nameValidation:",
    //   nameValidation
    // );

    if (
      !initiateValidationObj?.firstName?.trim() ||
      !initiateValidationObj?.lastName?.trim()
    ) {
      console.log("names failing");
      setNameValidation(false);
      return false;
    }

    var pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (!pattern.test(initiateValidationObj.email)) {
      setEmailValidation(false);
      return false;
    } else {
      setEmailValidation(true);
    }
    if (!phoneValidation.isValid) {
      return false;
    }
    setNameValidation(true);

    return true;
  };
  const handleBlur = (event) => {
    // Perform actions or execute functions when the input field loses focus
    console.log("Input field blurred");
    setPhoneOnBlur(true);
    console.log("🚀 ~ file: DonationForm.jsx:360 ~ handleBlur ~ true:", true);
  };
  return (
    <>
      <Box className="placeholder-gray">
        <Box
          backgroundColor="#e9ecef"
          rounded={"0.3rem"}
          overflow={"clip"}
          padding={"0.5rem"}
          my="1rem"
        >
          <Flex align="center" justify="space-between" mb="1.5rem" pr="1rem">
            <Flex gap="1rem" align={"center"}>
              <Image src="/card.jpg" alt="" width="1.5rem" rounded={"5px"} />
              <span>Pay with Visa Card</span>
            </Flex>
            <Flex
              align="center"
              cursor={"pointer"}
              onClick={() => handleCancel()}
            >
              <BiChevronLeft size={"1rem"} color="grey" />
              <span style={{ fontSize: "0.7rem" }}>Cancel</span>
            </Flex>
          </Flex>
          <>
            <Box ml="3rem">
              <Flex>
                <FormControl mr="5%">
                  {/* <FormLabel htmlFor="first-name" fontWeight={"normal"}>
                    First name
                  </FormLabel> */}
                  <Input
                    id="first-name"
                    placeholder="First name"
                    backgroundColor="white"
                    onBlur={() => setOnBlur(onBlur)}
                    onChange={(e) => {
                      setInitiateValidationObj({
                        ...initiateValidationObj,
                        firstName: e.target.value,
                      });
                    }}
                    onPaste={(e) => {
                      setInitiateValidationObj({
                        ...initiateValidationObj,
                        firstName: e.target.value,
                      });
                    }}
                    border={
                      !nameValidation && !initiateValidationObj?.firstName
                        ? "solid 1px red"
                        : "none"
                    }
                  />
                </FormControl>

                <FormControl>
                  {/* <FormLabel htmlFor="last-name" fontWeight={"normal"}>
                    Last name
                  </FormLabel> */}
                  <Input
                    id="last-name"
                    placeholder="Last name"
                    backgroundColor="white"
                    placeholderTextColor="blue"
                    onBlur={() => setOnBlur(onBlur)}
                    border={
                      !nameValidation && !initiateValidationObj?.lastName
                        ? "solid 1px red"
                        : "none"
                    }
                    onChange={(e) => {
                      setInitiateValidationObj({
                        ...initiateValidationObj,
                        lastName: e.target.value,
                      });
                    }}
                    onPaste={(e) => {
                      setInitiateValidationObj({
                        ...initiateValidationObj,
                        lastName: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Flex>
              {!nameValidation && (
                <Box color={"red"} fontSize={"small"} mb={"0.5rem"}>
                  Invalid name
                </Box>
              )}
              <Flex>
                <FormControl mt={"0.5rem"}>
                  {/* <FormLabel htmlFor="first-name" fontWeight={"normal"}>
                    First name
                  </FormLabel> */}
                  <Input
                    id="email"
                    placeholder="Email"
                    backgroundColor="white"
                    onBlur={() => setOnBlur(onBlur)}
                    onChange={(e) => {
                      setInitiateValidationObj({
                        ...initiateValidationObj,
                        email: e.target.value,
                      });
                    }}
                    onPaste={(e) => {
                      setInitiateValidationObj({
                        ...initiateValidationObj,
                        email: e.target.value,
                      });
                    }}
                    border={
                      !emailValidation && !initiateValidationObj?.email
                        ? "solid 1px red"
                        : "none"
                    }
                  />
                </FormControl>
              </Flex>
              {!emailValidation && (
                <Box color={"red"} fontSize={"small"} mb={"0.5rem"}>
                  Invalid Email
                </Box>
              )}
              <ChurchFamilySelect
                onChange={(e) =>
                  setInitiateValidationObj({
                    ...initiateValidationObj,
                    family: e.target.value,
                  })
                }
              />
              <FormControl mt="2%">
                <Flex gap="5px">
                  <div
                    style={{
                      border:
                        !phoneValidation.isValid && phoneOnBlur
                          ? "1px solid red"
                          : "none",
                    }}
                    onBlur={handleBlur}
                  >
                    <PhoneInput
                      defaultCountry="ug"
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                      hideDropdown
                    />
                  </div>
                </Flex>
                {!phoneValidation.isValid && phoneOnBlur && (
                  <Box color={"red"} fontSize={"small"} mb={"0.5rem"}>
                    Invalid Phone number
                  </Box>
                )}
                <FormHelperText fontSize="small" color="grey">
                  We'll never share your Contacts.
                </FormHelperText>
              </FormControl>
              <FormControl
                py="2rem"
                display="flex"
                visibility={"hidden"}
                alignItems="center"
                gap="0.5rem"
                fontSize="smaller"
                fontWeight="bold"
                ml="-1.5rem"
                defaultChecked
                onChange={(e) => {
                  setInitiateValidationObj({
                    ...initiateValidationObj,
                    processingCovered: e.target.checked,
                  });
                }}
              >
                <Checkbox isChecked defaultChecked backgroundColor="white" />{" "}
                <span>
                  {" "}
                  Add <span>
                    UGX {(total * 0.025).toLocaleString("en-US")}
                  </span>{" "}
                  to cover processing fee.{" (Optional)"}
                </span>
              </FormControl>
              <Box w={"100%"}>
                <Button
                  rounded="5rem"
                  width={"100%"}
                  background="linear-gradient(to right, red, yellow)"
                  color="white"
                  border="solid 2px gray"
                  _hover={{
                    background: "linear-gradient(to right, yellow, red)",
                  }}
                  onClick={() => {
                    if (validateUserDetails()) {
                      handleInitiateTransaction(initiateValidationObj);
                      makeAPICall();
                    }
                  }}
                >
                  Initiate Transaction
                </Button>
              </Box>
            </Box>
          </>
        </Box>
      </Box>
      <PaymentModal
        isOpen={isLoading}
        status={paymentStatus}
        onClose={onClosePaymentModal}
        onTryAgain={onTryAgain}
        onkeepWaiting={onKeepWaiting}
        payId={paymentId}
      />
    </>
  );
};
export default PayWithVisaCard;
