import React, { useEffect, useState } from "react";
import VideoInfo from "./VideoInfo";
import { useParams } from "react-router";

function VideoPlayer() {
  const usenaviagate = useParams();
  const { id } = usenaviagate;
  console.log("🚀 ~ VideoPlayer ~ id:", id);
  const [selectedVideo, setSelectedVideo] = useState(id);
  const [selectedVideoDetail, setSelectedVideoDetail] = useState(id);

  useEffect(() => {
    fetchVideos();
  }, []);

  async function fetchVideos() {
    return fetch(
      `${"https://api.kampalacentraladventist.org/"}api/YouTubeChannel/GetYoutubeVideos/${selectedVideo}`,
      // `${"https://localhost:7204/"}api/YouTubeChannel/GetYoutubeVideos?page=1&pageSize=5`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data) {
          console.log("error on api call", data);
          return;
        }
        setSelectedVideoDetail(data);

        console.log("🚀 ~ .then ~ data:", data);
      })
      .catch((error) => console.error(error))
      .finally(() => {});
  }
  return (
    <div className="videoplayer">
      <div className="video">
        <div className="video-container">
          <iframe
            width="1920"
            height="1080"
            src={`https://www.youtube.com/embed/${selectedVideo}?autoplay=1&controls=1&modestbranding=0&showinfo=0&rel=0&disablekb=1`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
            allowFullScreen
          ></iframe>
          ,
        </div>
        <VideoInfo selectedVideoDetail={selectedVideoDetail} />
      </div>
    </div>
  );
}

export default VideoPlayer;
