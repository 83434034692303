import React from "react";
import { Flex, Select } from "@chakra-ui/react";

const ChurchFamilySelect = ({ onChange }) => {
  return (
    <Flex my="5px">
      <Select background="white" onChange={onChange}>
        <option value="">Select Church Family (Optional)</option>
        <option value="Asher Family">Asher Family</option>
        <option value="Benjamin Family">Benjamin Family</option>
        <option value="Dan Family">Dan Family</option>
        <option value="Gad Family">Gad Family</option>
        <option value="Issachar Family">Issachar Family</option>
        <option value="Jacob Family">Jacob Family</option>
        <option value="Joseph Family">Joseph Family</option>
        <option value="Judah Family">Judah Family</option>
        <option value="Levi Family">Levi Family</option>
        <option value="Naphtali Family">Naphtali Family</option>
        <option value="Reuben Family">Reuben Family</option>
        <option value="Simeon Family">Simeon Family</option>
        <option value="Zebulon Family">Zebulon Family</option>
      </Select>
    </Flex>
  );
};

export default ChurchFamilySelect;
