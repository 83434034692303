import React from "react";

function Header() {
  return (
    <div className="header">
      <div className="header-left">
        <a href="#">
          <svg>
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
          </svg>
        </a>
        <a id="logo" href="#">
          <img src="images/youtube-logo.png" alt="YouTube Logo" />
        </a>
      </div>
      <div className="search wide">
        <form className="example" action="#">
          <input type="text" placeholder="Search" name="search" />
          <button type="submit">
            <svg>
              <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
            </svg>
          </button>
        </form>
      </div>
      <div className="header-right">{/* Add other icons similarly */}</div>
    </div>
  );
}

export default Header;
