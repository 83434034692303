import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Flex, Icon } from "@chakra-ui/react";
import { FaExclamationTriangle } from "react-icons/fa";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Spinner,
  Text,
  Button,
} from "@chakra-ui/react";
import { Box } from "@mantine/core";

const PaymentModal = ({
  isOpen,
  status,
  onClose,
  onTryAgain,
  onkeepWaiting,
  payId,
}) => {
  console.log(
    "🚀 ~ file: PaymentModal.jsx:11 ~ PaymentModal ~ status:",
    status
  );
  // console.log(
  //   "🚀 ~ file: PaymentModal.jsx:11 ~ PaymentModal ~ isOpen:",
  //   isOpen
  // );
  const handleOverlayClick = (event) => {
    // Prevent modal closing when clicking outside
    event.stopPropagation();
  };
  return (
    <Modal isOpen={isOpen} size="xl" isLazy>
      <ModalOverlay onClick={handleOverlayClick} />
      <ModalContent py={8}>
        <ModalBody textAlign="center">
          {status !== "approved" &&
            status !== "failed" &&
            status !== "timeout" && (
              <Spinner size="xl" thickness="5px" color="blue.500" mb="4" />
            )}
          {status === "failed" && (
            <CloseIcon boxSize={8} animate="pop" color="red.500" mb="4" />
          )}
          {status === "timeout" && (
            <Icon as={FaExclamationTriangle} boxSize={6} color="yellow.500" />
          )}
          {status === "approved" && (
            <CheckIcon w={16} h={16} color="green.500" animate="pop" mb="4" />
          )}
          <Text fontSize="lg">
            {status === "initiating" && "Initiating payment, please wait..."}
            {status === "waiting" &&
              "Waiting for payment authorization, please check your phone..."}
            {status === "approved" && "Payment received. Thank you for your support! May God bless you"}
            {status === "timeout" &&
              "Sorry, approval timed out. You can try again or keep waiting for approval"}
            {status === "failed" &&
              "Payment failed. Please try again or contact Admin."}
          </Text>
          <Flex gap="0.5rem" flexDirection="column" justifyContent="center">
            {status === "timeout" && (
              <Button
                mt={6}
                colorScheme="green"
                onClick={() => onkeepWaiting(payId)}
              >
                Keep Waiting
              </Button>
            )}
            {status === "timeout" && (
              <Button mt={6} colorScheme="yellow" onClick={onTryAgain}>
                Try again
              </Button>
            )}
            <Button mt={6} colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PaymentModal;
