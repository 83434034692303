import React, { useEffect } from "react";
import Header from "./components/Header";
import VideoPlayer from "./components/VideoPlayer";
import Sidebar from "./components/Sidebar";
import CommentsSection from "./components/CommentsSection";
import "./youtube.css";
import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import DonationForm from "../Donations/DonationForm";
import { Accordion, rem } from "@mantine/core";
import { PhoneIcon } from "@chakra-ui/icons";
import { MdCardGiftcard, MdCardMembership } from "react-icons/md";
import { BiCard } from "react-icons/bi";
import { Rings } from "react-loader-spinner";
import EbulletinPage from "../../Pages/EbulletinPage";

function YoutubeVideoPlayerMain() {
  useEffect(() => {
    scrollToTop();
  }, []); // Scroll to top when the component mounts
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: adds a smooth scrolling animation
    });
  }
  return (
    <div className="">
      {/* <Header /> */}
      <Box display={"flex"} flexDir={"column"} p={2}>
        <Box
          display={"flex"}
          flexDir={{ base: "column", lg: "row" }}
          justifyContent={"center"}
          alignItems={{ base: "center", lg: "flex-start" }}
          gap={"0.5rem"}
        >
          <Box flex={5} rounded={"1rem"} overflow={"clip"} my={2}>
            <VideoPlayer />
            {/* <CommentsSection /> */}
          </Box>
          <Box flex={2}>
            <Accordion variant="contained">
              <Accordion.Item value="photos">
                <Accordion.Control
                  icon={
                    <MdCardGiftcard
                      color="red"
                      size={"5rem"}
                      style={{
                        color: "green",
                        width: "rem",
                        height: rem(20),
                      }}
                    />
                  }
                >
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    height={"fit-content"}
                  >
                    <Text
                      as={"span"}
                      fontWeight={"bold"}
                      width={"100%"}
                      fontSize={"xl"}
                      color={"green"}
                    >
                      E - Giving{" "}
                    </Text>
                    <Rings
                      visible={true}
                      height="50"
                      width="50"
                      color="#4fa94d"
                      ariaLabel="rings-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </Box>
                </Accordion.Control>
                <Accordion.Panel>
                  <DonationForm completeOnPage={true} />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default YoutubeVideoPlayerMain;
