import React, { useEffect, useState } from "react";
import { fadeIn, slideIn, staggerContainer } from "../../utils/motion";
import css from "./Hero.module.scss";
import { motion } from "framer-motion";
import { BiLocationPlus } from "react-icons/bi";
import * as Scroll from "react-scroll";
import {
  IconPlayerPlay,
  IconShoppingCart,
  IconHeartHandshake,
} from "@tabler/icons";
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useStatStyles } from "@chakra-ui/react";

const Hero = () => {

  const[ verseOfDay, setVerseOfDay] = useState({
    ref:"",
    text:""
  });
  const [switchVerse, setSwitchVerse] = useState(0);

  const scroller = Scroll.scroller;
  const navigate = useNavigate();
  const goToPageAndScroll = async (selector) => {
    await navigate("/");
    await scroller.scrollTo(selector, {
      duration: 500,
      smooth: true,
      offset: -75,
      spy: true,
    });
  };
useEffect(()=>{
fetchVerseOfTheDay()
},[switchVerse])
   async function fetchVerseOfTheDay() {
     return fetch(
       `https://labs.bible.org/api/?passage=random`,
       // `${"https://localhost:7204/"}api/Posts?page=${1}&userProfileId=&userName=`,
       {
         method: "GET",
         
       }
     )
       .then((res) => res.text())
       .then((data) => {
         console.log(
           "🚀 ~ file: SocialMedia.jsx ~ line 30 ~ .then ~ data",
           data
         );
         const result = data.split("</b>")
         const verseObj = {
          ref:result[0].replace("<b>",""),
          text:result[1].trim()
         }
         console.log("🚀 ~ .then ~ verseObj:", verseObj)
         setVerseOfDay(verseObj);
         setTimeout(() => {
          setSwitchVerse(switchVerse +1);
         }, 30*1000);

         if (!data) {
           console.log("error on api call", data);
           return;
         }

        
       })
       .catch((error) => console.error(error))
       .finally(() => {});
   }

  function handleNavigate() {
    
    navigate("/donation");
    console.log("🚀 ~ handleNavigate ~ here:", "navigating")
  }
  return (
    <section className={`paddings ${css.wrapper}`}>
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className={`innerWidth ${css.container}`}
      >
        <div className={css.upperElements}>
          <motion.span
            className="primaryText"
            variants={fadeIn("right", "tween", 0.2, 1)}
          >
            Welcome to,
            <br />
            <span>
              <b> SDA KAMPALA </b>
            </span>
            <br />
            Central Church.
          </motion.span>
          <motion.span
            className={`secondaryText`}
            variants={fadeIn("left", "tween", 0.4, 1)}
            style={{ maxWidth: "25rem" }}
          >
            {verseOfDay
              ? `${verseOfDay.text} - ${verseOfDay.ref} `
              : `For God so loved the world that he gave his one and only Son, that
            whoever believes in him shall not perish but have eternal life. -
            John 3:16 NIV`}
          </motion.span>
        </div>
        <div className={css.lowerElements}>
          <motion.div
            variants={fadeIn("right", "tween", 0.3, 1)}
            className={css.experience}
          >
            <div className="primaryText">
              <BiLocationPlus />
            </div>
            <div className="secondaryText">
              <div>Gadaffi Road Opp. LDC</div>
              <div>Kampala Uganda</div>
            </div>
          </motion.div>

          <motion.div
            variants={fadeIn("left", "tween", 0.5, 1)}
            className={css.certificate}
          >
            {/* <img src="./certificate.png" alt="" /> */}
            <motion.span
              className={`secondaryText`}
              variants={fadeIn("left", "tween", 0.4, 1)}
              style={{
                maxWidth: "25rem",
                fontSize: "0.7rem",
                lineHeight: "0.7rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <IconHeartHandshake />
                <span>
                  {" "}
                  We are building a house for our Lord <br />& Your
                  Contributions are welcome
                </span>
                <IconHeartHandshake />
              </div>
            </motion.span>
            <div className={css.flexColGap}>
              <span
                className="btn btn-primary"
                onClick={() => goToPageAndScroll("work")}
                style={{ display: "flex", minWidth: "11rem", zIndex: "1000" }}
              >
                <IconPlayerPlay
                  size={30}
                  strokeWidth={2}
                  color={"white"}
                  style={{ marginRight: "1rem" }}
                />
                LiveStreams
              </span>
              <span
                className="btn btn-success"
                style={{ display: "flex", minWidth: "11rem", zIndex: "1000" }}
                onClick={handleNavigate}
              >
                <IconShoppingCart
                  size={30}
                  strokeWidth={2}
                  color={"white"}
                  style={{ marginRight: "1rem" }}
                />
                E - Giving
              </span>
            </div>
          </motion.div>
        </div>
        <motion.div
          variants={fadeIn("up", "tween", 0.3, 1)}
          className={css.person}
        >
          <motion.img
            variants={slideIn("up", "tween", 0.5, 1.3)}
            src="./churchRender.png"
            alt=""
          />
        </motion.div>

        {/* <a className={css.email} href="mailto:zainkeepscode@gmail.com">
          zainkeepscode@gmail.com
        </a> */}
      </motion.div>
    </section>
  );
};

export default Hero;
