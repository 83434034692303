import React from "react";
import EbulletinDownloader from "../components/Ebulletin/Ebulletin";

const EbulletinPage = () => {
  return (
    <div>
      <EbulletinDownloader/>
    </div>
  );
};

export default EbulletinPage;
