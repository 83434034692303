import React, { useEffect } from "react";
import DonationForm from "../../components/Donations/DonationForm";
import { Box, Center } from "@chakra-ui/react";

const OffertoryPage = () => {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: adds a smooth scrolling animation
    });
  }

  useEffect(() => {
    scrollToTop();
  }, []); // Scroll to top when the component mounts

  return (
    <Box
      display={"flex"}
      flexDir={"row"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <DonationForm />
    </Box>
  );
};

export default OffertoryPage;
