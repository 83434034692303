import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  Collapse,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { Flex, Popover, NumberInput, Button } from "@mantine/core";
import { MinusIcon } from "@chakra-ui/icons";
import OffertoryData from "./OfferToryData";
import { keys } from "localforage";

const DonationComponent = ({ donationList, setDonationList }) => {
  console.log("🚀 ~ DonationComponent ~ donationList:", donationList);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [amount, setAmount] = useState(0);
  const modalRef = useRef();
  const handleCategoryClick = (category) => {
    console.log("🚀 ~ handleCategoryClick ~ category:", category);
    setSelectedCategory({ ...category, category: category.value });
  };

  const handleAddDonation = () => {
    setDonationList([...donationList, { ...selectedCategory, amount }]);

    setAmount(0);
  };

  const handleRemoveDonation = (categoryId) => {
    setDonationList(donationList.filter((item) => item.id !== categoryId));
  };

  const availableCategories = OffertoryData.filter((category) => {
    console.log("🚀 ~ DonationComponent ~ donationList:", donationList);
    return !donationList.find((item) => item.id === category.id);
  });
  // console.log(
  // "🚀 ~ DonationComponent ~ availableCategories:",
  // availableCategories
  // );

  return (
    <Box
      position="relative"
      mx={1}
      mt={1}
      display={"flex"}
      justifyContent={"center"}
    >
      <VStack spacing={4}>
        {/* Categories Section */}

        <Box>
          <HStack
            display={"flex"}
            gap={5}
            width={"100%"}
            maxWidth={{ base: "18rem", lg: "23rem" }}
            overflowX={"scroll"}
          >
            {availableCategories.map((category) => (
              <div
                key={category.id}
                onClick={() => {
                  handleCategoryClick(category);
                }}
              >
                <Popover
                  width={300}
                  trapFocus
                  position="bottom"
                  withArrow
                  shadow="md"
                >
                  <Popover.Target>
                    <Box>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        gap={"1rem"}
                      >
                        <img
                          src={category?.image}
                          style={{
                            minWidth: "5rem",
                            height: "5rem",
                            maxWidth: "5rem",
                            objectFit: "cover",
                            objectPosition: "center",
                            borderRadius: "50%",
                          }}
                        />
                        <Text fontSize="0.7rem">{category?.label}</Text>
                      </Box>
                    </Box>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <span>
                      <span>Enter amount for </span>
                      <Text fontWeight={"bold"}>{selectedCategory?.label}</Text>
                    </span>
                    <NumberInput
                      onChange={(value) => setAmount(value)}
                      min={0}
                      placeholder="Enter amount"
                      thousandSeparator=","
                      onPaste={(e) => {
                        const pastedText = e.clipboardData.getData("text");
                        const isNumeric = /^\d+$/.test(pastedText); // Regular expression to check if the pasted text is numeric

                        if (!isNumeric) {
                          e.preventDefault(); // Prevent the paste action if the text is not numeric
                          console.log(
                            "Non-numeric input detected. Paste prevented."
                          );
                        } else {
                          console.log("Pasted numeric input:", pastedText);
                        }
                      }}
                      onKeyDown={(e) => {
                        const allowedKeys = [
                          "0",
                          "1",
                          "2",
                          "3",
                          "4",
                          "5",
                          "6",
                          "7",
                          "8",
                          "9",
                          "Backspace",
                          "ArrowLeft",
                          "ArrowRight",
                          "Delete",
                        ];
                        if (!allowedKeys.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          handleAddDonation();
                        }
                      }}
                    />

                    <Button
                      mt={4}
                      onClick={handleAddDonation}
                      style={{ borderRadius: "1rem" }}
                    >
                      Add Donation
                    </Button>
                  </Popover.Dropdown>
                </Popover>
              </div>
            ))}
          </HStack>
        </Box>

        {/* Donation List Section */}
        <Box width={"90%"}>
          <br />
          {donationList.map((donation) => (
            <Box display={"flex"} flexDir={"column"} width={"100%"}>
              <Box
                key={donation.id}
                justifyContent="space-between"
                align={"center"}
                display={"flex"}
                width={"100%"}
              >
                <Text>{donation.label}</Text>
                <Flex gap={"1rem"} alignItems={"center"}>
                  <Text>UGX {donation.amount.toLocaleString("en-US")}</Text>
                  <Box
                    aria-label="Remove donation"
                    onClick={() => handleRemoveDonation(donation.id)}
                  >
                    <MinusIcon color="red" />
                  </Box>
                </Flex>
              </Box>
              <hr style={{ margin: "5px 0" }} />
            </Box>
          ))}
        </Box>
      </VStack>
    </Box>
  );
};

export default DonationComponent;
