import React, { useEffect, useRef, useState } from "react";
import css from "./Header.module.scss";
import { BiPhoneCall, BiMenuAltRight } from "react-icons/bi";
import { motion } from "framer-motion";
import { getMenuStyles, headerVariants } from "../../utils/motion";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import useHeaderShadow from "../../hooks/useHeaderShadow";

import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { Link as ScrollLink } from "react-scroll";
import * as Scroll from "react-scroll";
import Portfolio from "../Portfolio/Portfolio";

const Header = () => {
  const path = useLocation().pathname;
  console.log("🚀 ~ Header ~ path:", path);
  const location = path.split("/")[1];
  const navigate = useNavigate();
  const scroller = Scroll.scroller;

  const menuRef = useRef(null);
  const [menuOpened, setMenuOpened] = useState(false);
  const headerShadow = useHeaderShadow();

  //to handle click outside of sidebar on mobile
  useOutsideAlerter({
    menuRef,
    setMenuOpened,
  });

  const goToPageAndScroll = async (selector) => {
    await navigate("/");
    await scroller.scrollTo(selector, {
      duration: 500,
      smooth: true,
      offset: -75,
      spy: true,
    });
  };

  return (
    <motion.div
      variants={headerVariants}
      initial="hidden"
      whileInView="show"
      className={`bgPrimary paddings ${css.wrapper}`}
      viewport={{ once: true, amount: 0.25 }}
      style={{ boxShadow: headerShadow }}
    >
      <div className={`innerWidth ${css.container} flexCenter`}>
        <div
          className={css.flexGap}
          style={{ cursor: "pointer" }}
          onClick={async () => {
            await navigate("/");
            window.scrollTo(0, 0);
          }}
        >
          <img src="/logo.png" alt="" className={css.leftLogo} />
          <div className={`primaryText  ${css.name}`}>SDAKCC</div>
        </div>
        <ul
          className={`flexCenter ${css.menu}`}
          ref={menuRef}
          style={getMenuStyles(menuOpened)}
          onClick={() => setMenuOpened(false)}
        >
          {!(
            location.startsWith("articles") ||
            location.startsWith("donation") ||
            location.startsWith("ebulletin")
          ) ? (
            <>
              <li>
                <a
                  onClick={() => {
                    navigate("/ebulletin/thisweek");
                    setMenuOpened((prev) => !prev);
                  }}
                >
                  {" "}
                  E&nbsp;bulletin
                </a>
              </li>
              <li>
                <a href="#experties"> Social&nbsp;Media</a>
              </li>
              <li>
                <a href="#articles">Articles</a>
              </li>
              <li>
                <a
                  onClick={() => {
                    navigate("/donation");
                    setMenuOpened((prev) => !prev);
                  }}
                >
                  E&nbsp;Giving
                </a>
              </li>
              <li>
                <a href="#people">More</a>
              </li>
              <li
                className={`${css.phone}`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <span>256 (772) 494 397</span>
                <BiPhoneCall size={"40px"} />
              </li>
            </>
          ) : (
            <>
              <li>
                <a
                  onClick={() => {
                    navigate("/ebulletin/thisweek");
                    setMenuOpened((prev) => !prev);
                  }}
                >
                  {" "}
                  E&nbsp;bulletin
                </a>
              </li>
              <li>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    goToPageAndScroll("experties");
                    setMenuOpened((prev) => !prev);
                  }}
                >
                  {" "}
                  Social&nbsp;Media
                </span>
              </li>
              <li>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    goToPageAndScroll("work");
                    setMenuOpened((prev) => !prev);
                  }}
                >
                  Articles
                </span>
              </li>
              <li>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/donation");
                    setMenuOpened((prev) => !prev);
                  }}
                >
                  E&nbsp;Giving
                </span>
              </li>
              <li>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    goToPageAndScroll("people");
                    setMenuOpened((prev) => !prev);
                  }}
                >
                  More
                </span>
              </li>
              <li
                className={`${css.phone}`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <span>256 (772) 494 397</span>
                <BiPhoneCall size={"40px"} />
              </li>
            </>
          )}
        </ul>

        {/* for medium and small screens */}
        <div
          className={css.menuIcon}
          onClick={() => setMenuOpened((prev) => !prev)}
        >
          <BiMenuAltRight size={30} />
        </div>
      </div>
    </motion.div>
  );
};

export default Header;
