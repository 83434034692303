import React, { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";

function EbulletinDownloader() {
  const docs = [
    {
      uri: "https://api.kampalacentraladventist.org/api/Ebulletin/latest",
      fileType: "pdf",
      fileName: "Kcc Ebulletin thisweek",
    },
  ];

  return (
    <div>
      <DocViewer
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        style={{ height: "200vh" }}
        config={{
          header: {
            disableHeader: false,
            disableFileName: false,
            retainURLParams: false,
          },
          csvDelimiter: ",", // "," as default,
          pdfZoom: {
            defaultZoom: 1.1, // 1 as default,
            zoomJump: 0.0, // 0.1 as default,
          },
          pdfVerticalScrollByDefault: true, // false as default
        }}
      />
    </div>
  );
}

export default EbulletinDownloader;
