import { Box, Image } from "@chakra-ui/react";
import React from "react";
import dateFormat from "dateformat";

function VideoInfo({ selectedVideoDetail }) {
  return (
    <div className="video-info border-bottom">
      <Box display={"flex"} gap={"1rem"} justifyContent={"flex-start"}>
        <Image
          src="/logo.png"
          width={"4rem"}
          objectFit={"contain"}
          objectPosition={"center"}
          overflow={"clip"}
        />
        <Box
          className="video-title"
          display={"flex"}
          flexDir={"column"}
          justifyContent={"flex-start"}
          alignItems={"start"}
        >
          <h1>{selectedVideoDetail?.title}</h1>
          <span>
            {dateFormat(selectedVideoDetail?.publishTime)}{" "}
            {selectedVideoDetail?.description}
          </span>
          <div className="like-share">
            {/* Like, Share, and other buttons */}
          </div>
        </Box>
      </Box>
      <div className="owner">{/* Owner details and description */}</div>
    </div>
  );
}

export default VideoInfo;
